// USADO PRA FAZER ROTAS PRA NOVIOS E CONVIDADOS, VIDE ANOTACOES.MD
// E QUERIES DO GATSBY
import React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';

//import BrideWelcomePage from '../pages/BrideWelcomePage';

// Importar Link do Gatsby e Router do @reach/router
// Vide documentação do Reach!
const Page = () => (
  <div>
    teste
  </div>
);

export const IndexPage = () => {
  return (
      <Router>
        <Page path="/app/b" />
      </Router>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query AppQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
